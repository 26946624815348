@import url(https://fonts.googleapis.com/css2?family=Varela+Round&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Righteous&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.floating {
	transform: scale(1);
	-webkit-animation: floater 3s ease-in-out infinite;
	        animation: floater 3s ease-in-out infinite;
	overflow: hidden;
}

@-webkit-keyframes floater {
	0% {
		transform: scale(1);
	}
	25% {
		transform: scale(1.1);
	}
	50% {
		transform: scale(1.2);
	}
	75% {
		transform: scale(1.1);
	}
	100% {
		transform: scale(1);
	}
}

@keyframes floater {
	0% {
		transform: scale(1);
	}
	25% {
		transform: scale(1.1);
	}
	50% {
		transform: scale(1.2);
	}
	75% {
		transform: scale(1.1);
	}
	100% {
		transform: scale(1);
	}
}
input {
	background-image: none;
	background-color: transparent;
	box-shadow: none;
	color: white;
}

input:focus {
	outline: none;
}

input::-webkit-input-placeholder {
	color: rgba(255, 255, 255, 0.753);
}

input:-ms-input-placeholder {
	color: rgba(255, 255, 255, 0.753);
}

input::-ms-input-placeholder {
	color: rgba(255, 255, 255, 0.753);
}

input::placeholder {
	color: rgba(255, 255, 255, 0.753);
}

.headline {
	color: white;
	font-family: 'Poppins', sans-serif;
	font-weight: 500;
	max-width: 635px;
}

.subline {
	color: rgba(255, 255, 255, 0.918);
	font-family: 'Poppins', sans-serif;
	font-weight: 300;
	max-width: 480px;
	padding-top: 25px;
}

.input {
	width: 70%;
	border: none;
}

.side-img {
	width: 100%;
	height: auto;
}

button:focus {
	outline: none;
}

@-webkit-keyframes spinner3 {
	to {
		transform: rotate(360deg);
	}
}

@keyframes spinner3 {
	to {
		transform: rotate(360deg);
	}
}

